.button-35 {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    color: #121212;
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-family: Inter, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    padding: 1rem 1.2rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s, -webkit-box-shadow .2s;
    white-space: nowrap;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.button-35:hover {
    box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
    color: #F7C800;
}
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background: #F7C800;
}
.nav-pills .nav-link {
    color: black;
}
.slick-slider {
margin:0 -15px;
}
.slick-slide {
padding:10px;
text-align:center;
margin-right:15px;
margin-left:15px;
}