// RESET
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}

#thqh6 {
  text-align: justify;
  margin-left: 80px;
  margin-right: 230px;
  color: #333;
  line-height: 2.5;
}
.numbered h4 {
  text-align: left;
  margin-left: 80px;
  line-height: 1.5;
}

// TIMELINE ORDERED LIST
ol.numbered {
  $bullet-line-width: 3px;
  $bullet-color: #fd7702;
  $bullet-border-width: $bullet-line-width;
  $bullet-background-color: white;
  $bullet-size: 45px;
  border-left: $bullet-line-width solid $bullet-color;
  counter-reset: numbered-list;
  margin-left: 300px;

  margin-top: 20px;

  li {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 20px;

    &:last-child {
      border-left: $bullet-line-width solid $bullet-background-color;
      margin-left: -$bullet-line-width;
    }

    &:before {
      $half-of-font-size: -0.5em;
      background-color: $bullet-color;
      border: $bullet-border-width solid $bullet-background-color;
      border-radius: 50%;
      color: $bullet-background-color;
      content: counter(numbered-list, decimal);
      counter-increment: numbered-list;
      display: block;
      font-weight: bold;
      width: $bullet-size;
      height: $bullet-size;
      margin-top: $half-of-font-size;
      line-height: $bullet-size;
      position: absolute;
      // left: -$bullet-size / 2 - $bullet-line-width / 2 - $bullet-border-width;
      left: 280px;
      text-align: center;
    }
  }
}

@media (max-width: 765px) {
  // RESET

  .numbered h4 {
    margin-left: 60px;
  }
  #thqh6 {
    margin-left: 60px;
  }
  // TIMELINE ORDERED LIST
  ol.numbered {
    $bullet-line-width: 3px;
    $bullet-color: #fd7702;
    $bullet-border-width: $bullet-line-width;
    $bullet-background-color: white;
    $bullet-size: 45px;
    border-left: $bullet-line-width solid $bullet-color;
    counter-reset: numbered-list;
    margin-left: 50px;

    margin-top: 20px;

    li {
      font-size: 10px;
      line-height: 1.2;
      margin-bottom: 20px;

      &:last-child {
        border-left: $bullet-line-width solid $bullet-background-color;
        margin-left: -$bullet-line-width;
      }

      &:before {
        $half-of-font-size: -0.5em;
        background-color: $bullet-color;
        border: $bullet-border-width solid $bullet-background-color;
        border-radius: 50%;
        color: $bullet-background-color;
        content: counter(numbered-list, decimal);
        counter-increment: numbered-list;
        display: block;
        font-weight: bold;
        font-size: large;
        width: $bullet-size;
        height: $bullet-size;
        margin-top: $half-of-font-size;
        line-height: $bullet-size;
        position: absolute;
        left: 30px;
        text-align: center;
      }
    }
  }
}

// rightside bar

.sidebarsg,
.main {
  transition: all 0.3s ease-out;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: -20px;
  bottom: 0;
}

.sidebarsg {
  width: 290px;
  background-color: white;
  left: 0;
  margin-left: 970px;
}


.main {

  overflow-x: hidden;
  background-color: #f5f5f5;
  position: absolute;
  // left: 50px;
  // right: 400px;

}

.toggle-menu {
  color: #ccc;
  text-decoration: none;
  font-size: 50px;
  float: left;
  display: block;
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  &:hover {
    color: #fff;
  }
  &:before {
    height: 64px;
    width: 64px;
    display: block;
    text-align: center;
    line-height: 64px;
    font-size: 24px;
  }
}
#sg2 li {
  text-align: left;
  font-size: 15px;
  padding: 20px;
  color: #333;
  background-color: white;
  margin-bottom: 1px;
  border-left: 10px solid transparent;
  // margin-left: -50px;
}
.active1 {
  color: #fd7702;
}
@media (max-width: 768px) {
  .sidebarsg {
    width: 160px;
    background-color: white;
    left: 0;
    margin-left: 230px;
  }
  #sg2 li {
    font-size: 11px;
    padding: 6px;
    color: #333;
    background-color: white;
    margin-bottom: 1px;
    border-left: 5px solid transparent;
    margin-left: -40px;
  }
}

// @media (min-width: 1920px) {
//   .sidebarsg {
//     // width: 320px;
//     background-color: white;
//     left: 0;
//     margin-left: 1300px;
//   }
//   #sg2 li {
//     font-size: 13px;
//     padding: 6px;
//     color: #333;
//     background-color: white;
//     margin-bottom: 1px;
//     border-left: 5px solid transparent;
//     margin-left: -50px;
//   }
//   .main {
//     // overflow-y: hidden; /* Hide vertical scrollbar */
//     overflow-x: hidden;
//     background-color: #f5f5f5;
//     position: absolute;
//     // left: 50px;
//     right: 550px;}
  
//     // padding: 20px;}
  
// }

// @media (min-width:2560px){

//   .sidebarsg,
//   .main {
//     transition: all 0.3s ease-out;
//     overflow: scroll;
//     -webkit-overflow-scrolling: touch;
//     position: absolute;
//     top: 64px;
//     bottom: 0;
//   }
  
//   .sidebarsg {
//     // position: fixed;
//     width: 350px;
//     background-color: white;
//     left: 0;
//     margin-left: 1970px;
//   }
  
//   .main {
  
//     overflow-x: hidden;
//     background-color: #f5f5f5;
//     position: absolute;
//     left: 50px;
//     right: 400px;
  
//   }
// }


// blog pulse animation
#suri1{
  width: 40%;
  border-radius: 20px;
}
#suri:hover{
  color: white;
}
.pulsating-circle {
  margin-top: 12px;
  margin-left: 8px;
  position: absolute;
  // left: 50%;
  // top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 15px;
  height: 15px;
  
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 290%;
    height: 290%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: whitesmoke;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  
  &:after {
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

