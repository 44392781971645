/* tools css */

.container3 {
  width: 100%;
  /* max-width: 960px; */
  margin: 30px auto;
  position: relative;
  text-align: center;
  margin-top: -30px;
}

#toolsnav {
  display: block;
  width: 100%;
  margin-left: -10px;
}

ul {
  list-style: none;
  margin-bottom: 20px;
}

#toolsnav > ul > li {
  display: inline-block;
  margin: 10px;
}
#toolsnav > ul > li > a {
  text-transform: capitalize;
  /* padding: 8px 20px; */
  padding: 10px;
  margin: 10px;
  margin-right: 2px;
  margin-left: 2px;
  text-decoration: none;
  /* color:  #04165d; */
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 25px;
  border: 1px solid #04165d;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-size: 16px;
}

.hidden {
  display: none;
}

#toolsnav > ul > li > a:hover,
.current a {
  color: #fff;
  background-color: #04165d;
}

#projects > ul > li {
  display: inline-block;
  /* margin-right: 10px; */
  margin-bottom: 5px;
  width: 15%;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  /* Padding stays within the width */
  box-sizing: border-box;
  position: relative;
  opacity: 0.7;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

#projects > ul > li:hover {
  opacity: 1;
}

img {
  /* max-width: 100%; */
  border-radius: 5px;
}

.gallery2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 40px 10px;
  display: none;
  box-sizing: border-box;
}

.gallery2 > img {
  max-height: 100%;
  width: auto;
}
@media (max-width: 768px) {
  #projects > ul > li {
    width: 40%;
  }
  #toolsnav > ul > li {
    display: inline-block;
    margin: 9px;
  }
}
#tool1 {
  margin: 10px;
  height: 130px;
  width: 150px;
  background-color: white;
  border-radius: 15px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
}
#tool1 img {
  margin-top: 20px;
}
#tool1 h6 {
  margin-top: 20px;
}
#toolstop {
  margin-top: -350px;
}
/* tools */
@media (max-width: 765px) {
  #toolsnav {
    margin-left: -15px;
  }
  #toolsh6 {
    font-size: 15px;
  }
  #toolsnav > ul > li > a {
    text-transform: capitalize;
    margin: 30px;
    margin-right: 2px;
    margin-left: 2px;
    text-decoration: none;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 25px;
    border: 1px solid #04165d;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    font-size: 11px;
  }
  #toolstop {
    margin-top: -150px;
  }

  #tool1 {
    margin: 20px;
    height: 130px;
    width: 110px;
    margin-left: -5px;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(80, 61, 61, 0.35) 0px 5px 15px;
  }
  .container3 {
    width: 90%;
    max-width: 960px;
    margin: 30px auto;
    position: relative;
    text-align: center;
    margin-top: -60px;
  }
}
