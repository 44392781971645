/* width */
::-webkit-scrollbar {
  width: 0px;
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent; 
}


#whowe {
  text-align: left;
}

#dc1 {
  display: block;
}
.stats {
  list-style: none;
}

/* Utilities */
.c::after,
.c img {
  border-radius: 50%;
}
.c,
.stats {
  display: flex;
}

.c {
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 350px;
  height: 220px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.c::before,
.c::after {
  content: "";
  position: absolute;
  z-index: -1;
}
.c::before {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  top: -0.7rem;
  left: -0.7rem;
}
.c::after {
  height: 15rem;
  width: 15rem;
  background-color: #04165d;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #fff;
}

.c img {
  width: 4rem;
  height: 70px;
  min-width: 80px;
  box-shadow: 0 0 0 5px #fff;
}

.infos {
  margin-left: 1.5rem;
}

.name h4 {
  font-size: 0.8rem;
  color: #333;
}

.text {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
select {
  width: 530px;
  outline: none;
  border: 1px solid lightgray;
  padding: 1rem;
  /* font-size: 1.0rem; */
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
  cursor: pointer;
  color: darkgray;
  height: 58px;
}
/* text select from whole screen */
::selection {
  color: #fff;
  background: #b0d4fd;
}
.wrapper {
  max-width: 1080px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}
.wrapper .row {
  display: flex;
}
.wrapper .row-1 {
  justify-content: flex-start;
}
.wrapper .row-2 {
  justify-content: flex-end;
}
.wrapper .row section {
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
}
.wrapper .row section::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  top: 28px;
  z-index: -1;
  transform: rotate(45deg);
}
.row-1 section::before {
  right: -7px;
}
.row-2 section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ff7979;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.row-1 section .icon {
  top: 15px;
  right: -60px;
}
.row-2 section .icon {
  top: 15px;
  left: -60px;
}
.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
}
.row section p {
  margin: 10px 0 17px 0;
}
.row section .bottom a {
  text-decoration: none;
  background: #ff7979;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  font-weight: 400;
  transition: all 0.3s ease;
}
.row section .bottom a:hover {
  transform: scale(0.97);
}
@media (max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .row-1 section::before {
    left: -7px;
  }
  .row-1 section .icon {
    left: -60px;
  }
}
@media (max-width: 440px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }
  .wrapper .row {
    margin: 10px 0;
  }
}

.circle {
  padding: 16px 20px;
  border-radius: 30%;
  background-color: #0f2ca0;
  color: #fff;
  max-height: 65px;
  max-width: 65px;
  border-radius: 50%;
  z-index: 2;
  align-items: center;
}
p {
  color: #88818b;
}
h2 {
  color: #04165d;
  font-weight: bold;
}
.connecting-lines.row .col-2 {
  align-self: stretch;
}
.connecting-lines.row .col-2::after {
  content: "";
  position: absolute;
  border-left: 3px solid #04165d;
  z-index: 1;
}
.connecting-lines.row .col-2.bottom::after {
  height: 50%;
  left: 50%;
  top: 50%;
}
.connecting-lines.row .col-2.full::after {
  height: 100%;
  left: calc(50% - 3px);
}
.connecting-lines.row .col-2.top::after {
  height: 50%;
  left: 50%;
  top: 0;
}

.timeline div {
  padding: 0;
  height: 40px;
}
.timeline hr {
  border-top: 3px solid #04165d;
  margin: 0;
  top: 17px;
  position: relative;
}
.timeline .col-2 {
  display: flex;
  overflow: hidden;
}
.timeline .corner {
  border: 3px solid #04165d;
  width: 100%;
  position: relative;
  border-radius: 15px;
}
.timeline .top-right {
  left: 50%;
  top: -50%;
}
.timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}
.timeline .top-left {
  left: -50%;
  top: -50%;
}
.timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}

/* card */
.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: #1369ce;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #1369ce;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #1369ce;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color: #1369ce;
  background-color: #f7f5ec;
}

/* mobile app */

.card-img-top {
  height: 100px;
  width: 60%;
}
.card-img-top1 {
  height: 60px;
  width: 60px;
}
.card {
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.card:hover {
  color: white;
}

#p {
  color: rgb(144, 144, 144);
  text-align: center;
}
#p:hover {
  color: white;
}
.card:hover::before {
  left: 0%;
  color: white;
}
.container-fluid {
  background-position: center;
  background-size: cover;
}
.text-center {
  font-size: 1.2rem;
}

.wrapper h1 {
  font-family: "Allura", cursive;
  font-size: 52px;
  margin-bottom: 60px;
  text-align: center;
}

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  width: auto;
}

.team .team_member {
  margin: 5px;
  margin-bottom: 50px;
  width: 200px;
  padding: 20px;
  line-height: 20px;
  color: #8e8b8b;
  position: relative;
}

.team .team_member h3 {
  color: white;
  font-size: 1rem;
  margin-top: 50px;
}

.team .team_member p.role {
  color: #ccc;
  margin: 12px 0;
  font-size: 12px;
  text-transform: uppercase;
}

.team .team_member .team_img {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
}
.team .team_member .team_img img {
  width: 100px;
  height: 100px;
  padding: 5px;
}
.team .team_member .team_imgE {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #04165d;
}
.team .team_member .team_imgE img {
  width: 70px;
  height: 70px;
  margin-top: 15px;
}

/* testimonail */
#back1 {
  background-image: url("./images/test.JPG");
  background-repeat: no-repeat;
}
#testh1 {
  display: none;
}

.btn-whatsapp-pulse {
  background: #25d366;
  color: white;
  position: fixed;
  bottom: 50px;
  right: 20px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none;
  border-radius: 70%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse-border {
  bottom: 270px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.75;
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/*get in  touch */

.btn-whatsapp-pulse1 {
  background: #fd7702;
  color: white;
  position: fixed;
  bottom: 360px;
  right: 20px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse1;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse1 {
  0% {
    box-shadow: 0 0 0 0 #fd7702;
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse1-border {
  bottom: 170px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.75;
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/*phone call */

.btn-whatsapp-pulse2 {
  background: #00aff0;
  color: white;
  position: fixed;
  bottom: 180px;
  right: 20px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse2;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
#a1 {
  color: white;
  text-decoration: none;
}
#aa1 {
  color: white;
  text-decoration: none;
}
#aaa1 {
  color: white;
  text-decoration: none;
}

@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 #00aff0;
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse2-border {
  bottom: 170px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.75;
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* about us expertise */
[data-toggle="collapse"] .fa:before {
  content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}

/* xxxxxxxxxxxxxxxxxxxxxxxxxxx */
.img-border{
  opacity: 0;
    animation: fadeIn 1s forwards;
}
.ani1 {
  animation: slideIn 1s forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(500px);
  }
  to {
    opacity: 1;
  }
}





/* right */
.ani2 {
  animation: slideInn 1s forwards;
}
@keyframes fadeInn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideInn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}


@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(500px);
  }
  to {
    opacity: 1;
  }
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}
.animation-element {
  position: relative;
  width: 30%;
  margin: 0% 1.33 2.66% 1.33%;
  float: left;
}
.animation-element:nth-of-type(3n-2) {
  width: 31.5%;
  margin: 0% 1.375% 2.75% 0%;
  clear: left;
}

.animation-element:nth-of-type(3n-1) {
  width: 31.5%;
  margin: 0% 1.375% 2.75% 1.375%;
}

.animation-element:nth-of-type(3n-0) {
  width: 31.5%;
  margin: 0% 0% 2.75% 1.375%;
  clear: right;
}
/*bounce up animation for the subject*/

.bounce-up .subject {
  opacity: 0;
  -moz-transition: all 700ms ease-out;
  -webkit-transition: all 700ms ease-out;
  -o-transition: all 700ms ease-out;
  transition: all 700ms ease-out;
  -moz-transform: translate3d(0px, 200px, 0px);
  -webkit-transform: translate3d(0px, 200px, 0px);
  -o-transform: translate(0px, 200px);
  -ms-transform: translate(0px, 200px);
  transform: translate3d(0px, 200, 0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bounce-up.in-view .subject {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.subject {
  float: left;
  width: 100%;
  margin: 0% 1.33% 2.66% 1.33%;
  background: #f5f5f5;
  padding: 15px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  border: solid 1px #eaeaea;
}

.subject:hover,
.subject:active {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.subject .header {
  margin: 8px 0px;
}

.subject .header .date,
.subject .header .category {
  margin: 0px;
  clear: none;
  width: 50%;
}

.subject .header .date i,
.subject .header .category i {
  margin-right: 5px;
  color: #333;
  -moz-transition: all 400ms linear;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.subject .header .date {
  float: left;
  text-align: left;
}

.subject .header .category {
  float: right;
  text-align: right;
}
/*subject icon at top*/

.subject .icon {
  position: absolute;
  top: -20px;
  left: 50%;
  left: calc(50% - 20px);
}

.subject .icon i {
  width: 40px;
  height: 40px;
  color: rgb(255, 255, 255);
  font-size: 150%;
  border-radius: 50%;
  text-align: center;
  padding: 7px;
}
/*Category color / icon color*/

.subject .category-color {
  height: 7px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.subject.design .category-color,
.subject.design .icon i {
  background: #c0392b;
}

.subject.development .category-color,
.subject.development .icon i {
  background: #16a085;
}

.subject.photography .category-color,
.subject.photography .icon i {
  background: #2980b9;
}
/*hovering over subject*/

.subject.development:hover .header i {
  color: #16a085;
}

.subject.design:hover .header i {
  color: #c0392b;
}

.subject.photography:hover .header i {
  color: #2980b9;
}

.subject .title {
  margin: 12px 0px;
  border-bottom: dashed 1px #ccc;
  padding-bottom: 10px;
}

.subject .content {
  margin-bottom: 8px;
}

.subject .enrole {
  position: relative;
  color: #333;
  padding: 5px 12px;
  cursor: pointer;
  /* float: right; */
  display: inline-block;
  border: solid 1px #ccc;
  -moz-transition: all 400ms linear;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.subject .enrole:hover,
.subject .enrole:active {
  background: #222;
  color: #fff;
}
/*media queries for small devices*/

@media screen and (max-width: 678px) {
  .main-container {
    margin: 20px 15px;
  }
  .animation-element,
  .animation-element:nth-of-type(3n-1),
  .animation-element:nth-of-type(3n-2),
  .animation-element:nth-of-type(3n-0) {
    width: 100%;
    margin: 0px 0px 30px 0px;
  }
  .subject .header .date,
  .subject .header .category {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}
::placeholder {
  color: darkgray;
}

.fact-item i {
  font-size: 48px;
}
.fact-item h1 {
  font-size: 34px;
}
.fact-item h5 {
  font-size: 15px;
  text-align: center;
}
#cardh {
  height: 240px;
  width: 228px;
}

#homeh2 {
  font-size: 1.7rem;
  text-align: center;
  /* margin-left: 130px; */
}

#homeh4 {
  font-size: 1.1rem;
}

#buttonH {
  margin-right: 300px;
  margin-top: -105px;
}
#buttonH1 {
  text-align: center;
}
#buttonH11 {
  margin-right: 130px;
  margin-top: -80px;
}
#homecall {
  height: 180px;
}

#homecall1 {
  height: 220px;
}

#whoweare2 {
  margin-left: 50px;
}
#webdiv {
  height: 90px;
  width: 100%;
}
#webh2 {
  margin-left: 90px;
  font-size: 1.7rem;
  text-align: left;
  font-weight: bold;
  margin-top: -8px;
}

#frameC {
  margin-top: -310px;
}

#iframe1 {
  width: 350px;
  height: 300px;

  border-radius: 14%;
}

#team2 img {
  /* margin-left: 60px; */
  margin-top: 10px;
  height: 54px;
  width: 54px;
}

#webcard {
  height: 190px;
  width: 228px;
}

body {
  text-align: center;
}
[type="radio"] {
  display: none;
}
label {
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.25s;
}
[type="radio"]:checked + label {
  background: #fd7702;
  color: white;
}
[type="radio"]:nth-of-type(2):checked ~ .gallery .card:not(.animals),
[type="radio"]:nth-of-type(3):checked ~ .gallery .card:not(.nature),
[type="radio"]:nth-of-type(4):checked ~ .gallery .card:not(.tech) {
  display: none;
}
.gallery {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-auto-rows: 1fr;
  grid-gap: 1rem;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 90%;
  margin-left: 50px;
}

/* (A) WRAPPER */
.fullwrap {
  position: relative; /* required for (c2) */
  max-width: 500px; /* optional */
}

/* (B) RESPONSIVE IMAGE */
.fullwrap img {
  width: 100%;
  height: 250px;
}

/* (C) CAPTION */
.fullcap {
  /* (C1) DIMENSIONS */
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  /* (C2) POSITION */
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 40%;

  /* (C3) COLORS */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

/* (D) SHOW/HIDE */
.fullcap {
  visibility: none;
  opacity: 0;
  transition: opacity 0.3s;
}
.fullwrap:hover .fullcap {
  visibility: visible;
  opacity: 1;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.fullWidth {
  width: 100%;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}
/********************************/
/*  SECTION WORK EXPERIENCE
********************************/

#work-experience .sectiontitle .headerLine {
  width: 280px;
}

#work-experience .headerline {
  width: 280px;
}

.cbp_tmtimeline {
  margin: 60px 30px 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: "";
  position: absolute;
  top: 3%;
  bottom: 0;
  width: 4px;
  background: white;
  left: 13%;
  height: 100%;
}

.cbp_tmtimeline li:last-child:before {
  content: initial;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 25%;
  padding-right: 100px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #bdd0db;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 2.9em;
  color: #3594cb;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #6cbfee;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  /* background: rgba(50, 68, 84, 1); */
  color: #fff;
  padding: 30px;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  font-family: "Open Sans";
  position: relative;
  border-radius: 5px;
  min-height: 150px;
}

/* .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: #2B3A48;
} */

.cbp_tmtimeline > li .cbp_tmlabel h3 {
  margin-top: 0px;
  color: white;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  font-family: "Open Sans", sans-serif;
  /* font-weight: bold; */
}

.cbp_tmtimeline > li .cbp_tmlabel h4 {
  opacity: 0.7;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  text-align: left;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 i {
  margin-right: 5px;
  vertical-align: middle;
}

/* .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #2B3A48;
} */

.cbp_tmtimeline > li .cbp_tmicon {
  width: 80px;
  height: 80px;
  /* top: 3%; */
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.1em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: white;
  background: white;
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}

.cbp_tmtimeline li {
  margin-bottom: 70px;
  position: relative;
}

.projectParagraph {
  font-size: 15px;
  margin: 0.5em 0 0;
  font-family: "normal";
  color: white;
}

.cbp_tmicon img {
  width: 100%;
}

.faPra {
  display: inline-block;
  font: normal normal normal 10px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 40px;
  vertical-align: middle;
  color: #04165d;
  line-height: 150px;
  margin-top: -40px;
}
.faPra img {
  border-radius: 80%;
  height: 54px;
  width: 54px;
}
#hidetestimonial {
  display: none;
  /* visibility: hidden; */
}
@media (max-width: 765px) {
  #hidetestimonial {
    display: block;
    visibility: visible;
  }

  #youtubevideo {
    margin-left: -90px;
  }
  #containerT {
    display: none;
  }
  .position-relative img {
    width: 20%;
    margin-left: -30px;
  }
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 30px 0 70px 0;
    padding: 50px 30px 30px 30px;
    font-weight: 400;
    font-size: 95%;
    float: left;
  }

  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmicon {
    position: relative;
    float: left;
    left: 120px;
    /* margin-left: 200px; */
    margin: 0px 5px 0 0px;
  }
  .cbp_tmtimeline > li:nth-child(even) .cbp_tmicon {
    position: relative;
    float: left;
    left: 120px;
    margin: 0px 5px 0 0px;
  }
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media (max-width: 32em) {
  .cbp-ntaccordion {
    font-size: 70%;
  }
}
/* AUTHOR LINK */

#contactForm1,
#contactForm2 {
  display: none;

  border: 3px solid #04165d;
  padding: 2em;
  width: 350px;
  text-align: center;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

#webform {
  margin: 0.8em auto;
  font-family: inherit;
  text-transform: inherit;
  font-size: inherit;

  display: block;
  width: 250px;
  padding: 0.4em;
}

#webform1 {
  height: 80px;
  width: 250px;
  resize: none;
}
.formBtn {
  width: 140px;
  display: inline-block;

  background: #04165d;
  color: #fff;
  font-weight: 100;
  font-size: 1.2em;
  border: none;
  height: 30px;
}
.wrapper1 {
  display: inline-flex;
}

.wrapper1 .dynamic-txts {
  margin-left: 15px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
}
.dynamic-txts li {
  color: white;
  list-style: none;
  font-size: 60px;
  position: relative;
  top: 0;
  animation: slide 6s steps(4) infinite;
}
@keyframes slide {
  100% {
    top: -280px;
  }
}
.dynamic-txts li span {
  position: relative;
}
.dynamic-txts li span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: #032471;
  border-left: 2px solid #032471;
  animation: typing 1.5s steps(15) infinite;
}
@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}

.devHead {
  margin-left: 160px;
  color: #04165d;
}

/* deisgn 18 video */

#designVideo {
  margin-top: -50px;
  height: 500px;
}
@media (max-width: 765px) {
  #designVideo {
    margin-top: 20px;
    height: 240px;
  }
}

/* loader */
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* end loader css*/

#nav1 {
  background-color: #032471;
}
.contentW {
  font-style: normal;
  font-weight: bold;
}

#contentW {
  font-style: normal;
  font-weight: bold;
}

/* conatct us 3 card */
#dc1 {
  /* max-width: 100%; */
  display: block;
}
.stats {
  list-style: none;
}

/* Utilities */
/* .c1::after,
.c1 img {
  border-radius: 50%;
} */
.c1,
.stats {
  display: flex;
}

.c1 {
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 500px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
  height: 200px;
  width: 100%;
}
.c1::before,
.c1::after {
  content: "";
  position: absolute;
  z-index: -1;
}
.c1::before {
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  top: -0.7rem;
  left: -0.7rem;
}

/* .c1 img {
  width: 2rem;
  min-width: 60px;
  box-shadow: 0 0 0 5px #fff;
  margin-top: 10px;
  margin-left: -20px;
  margin-bottom: 10px;
} */

#dc1{
  width: 2rem;
  min-width: 60px;
  box-shadow: 0 0 0 5px #fff;
  margin-top: 10px;
  margin-left: -20px;
  margin-bottom: 10px;
}
.infos {
  margin-left: 3.5rem;
  margin-top: 30px;
}

.name1 {
  margin-top: -40px;
  text-align: left;
  margin-left: -20px;
}
.name1 p {
  font-size: 1.2rem;
  color: #333;
  text-align: left;
  font-weight: bold;
  line-height: 0.2;
}

.name1 span {
  font-size: 0.9rem;
  color: #333;
  text-align: left;
}
#conrow {
  margin-left: -12px;
}

@media (max-width: 765px) {
  #conrow {
    margin-left: -12px;
  }
  #conrow1 {
    margin-left: 10px;
  }
  #dc1 {
    /* max-width: 100%; */
    display: block;
  }
  .stats {
    list-style: none;
  }

  /* Utilities */
  .c1::after,
  .c1 img {
    border-radius: 50%;
  }
  .c1,
  .stats {
    display: flex;
  }

  .c1 {
    padding: 2.5rem 2rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 500px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    margin: 1rem;
    position: relative;
    transform-style: preserve-3d;
    overflow: hidden;
    height: 180px;
    width: 100%;
  }
  @media(max-width:430px){
    .c1{
      padding: 0.5rem 2rem;
      padding: 37px;
    }
  }
  .c1::before,
  .c1::after {
    content: "";
    position: absolute;
    z-index: -1;
  }
  .c1::before {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    top: -0.7rem;
    left: -0.7rem;
  }

  .c1 img {
    width: 3rem;
    min-width: 60px;
    box-shadow: 0 0 0 5px #fff;
    margin-top: 10px;
    margin-left: -20px;
  }

  .infos {
    margin-left: 1.5rem;
    margin-top: 5px;
  }

  .name1 {
    margin-top: -20px;
    margin-left: -15px;
  }
  @media(max-width:390px){
    .name1{
      margin-top: -51px;
    }
  }
  .name1 p {
    font-size: 1.1rem;
    color: #333;
    margin-top: -60px;
    text-align: left;
    font-weight: bold;
  }
  .name1 span {
    font-size: 0.8rem;
    color: #333;
    margin-top: -50px;
    text-align: left;
  }
  #modalh {
    margin-top: -170px;
    margin-left: 100px;
  }
}

/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */
.wrapperr {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 86.9vh;
  padding: 20px;
  margin-top: -280px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 100%;
  max-width: 550px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #fd7702;
}

/* process */
#process {
  margin-bottom: 40px;
  overflow-x: hidden;
}
@media(max-width:390px){
    #process{
      margin-bottom: -68px;
    }
}
@media(max-width:430px){
  #process{
    margin-bottom: -68px;
  }
}

#process .section-heading {
  margin-bottom: 40px;
}

.steps-pane i {
  height: 35px;
  font-size: 50px;
  color: #04165d;
  margin-top: 40px;
}
.steps-pane #i {
  height: 35px;
  font-size: 50px;
  color: #fd7702;
  margin-top: 40px;
  /* -ms-transform: skewX(-15deg);
  */
}

.pane-warp {
  height: 140px;
  width: 15px;
  margin-left: 20%;
  margin-top: 20px;
  /* -ms-transform: skewX(15deg); */
  /* IE 9 */
  /* -webkit-transform: skewX(15deg); */
  /* Safari */
  /* -o-transform: skewX(15deg); */
  /* Standard syntax */
  /* -moz-transform: skewX(15deg); */
}

.steps-pane {
  height: 120px;
  width: 140px;
  margin-left: 0px;
  box-shadow: 7px 0px 5px #bcbcbc;
}
.inverted-steps-pane span {
  font-size: medium;
  font-weight: bold;
  color: #04165d;
  font-style: normal;
}
.inverted-steps-pane h6 {
  margin-top: 10px;
}
.inverted-pane-warp {
  height: 146px;
  width: 15px;
  margin-left: 20%;
  /* -ms-transform: skewX(-15deg); */
  /* IE 9 */
  /* -webkit-transform: skewX(-15deg); */
  /* Safari */
  /* -o-transform: skewX(-15deg); */
  /* Standard syntax */
  /* -moz-transform: skewX(-15deg); */
}

.inverted-steps-pane {
  height: 146px;
  width: 130px;
  background: #bcbcbc;
  margin-left: 14px;
  box-shadow: 0px 0px 0px #bcbcbc;
  margin-top: -80px;
}

@media (min-width: 900px) {
  .steps-timeline {
    border-top: 5px double #fd7702;
    padding-top: 20px;
    margin-top: 40px;

    margin-right: 3%;
  }
  .steps-one,
  .steps-two,
  .steps-three,
  .steps-four {
    float: left;
    width: 25%;
    margin-top: -105px;
  }
  .step-wrap {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
    margin-left: 39%;
    border: 2px solid #fd7702;
  }
  .verticle-line {
    position: absolute;
    height: 57px;
    width: 5px;
    margin-left: 10px;
    marker-top: 10px;
  }
  .steps-stops {
    height: 25px;
    width: 25px;
    margin: 11px 10.1px;
    border-radius: 50%;
    background: #fd7702;
  }
  .end-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: 19px;
    margin-left: -10px;
  }
  .inverted-end-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: 19px;
    margin-left: 18.1%;
  }
}

@media (max-width: 899px) {
  #process .container-fluid {
    width: 50%;
  }
  .steps-timeline {
    border-left: 5px double #fd7702;
    margin-left: 35px;
  }
  .steps-one,
  .steps-two,
  .steps-three,
  .steps-four,
  .steps-five {
    margin-left: -25px;
  }

  .step-wrap,
  .steps-stops {
    float: left;
  }
  .steps-timeline {
    border-left: 2px solid #fd7702;
    margin-left: -10px;
  }
  .pane-warp {
    margin-left: 30%;
  }
  .inverted-pane-warp {
    margin-left: 30%;
  }
  .verticle-line {
    position: absolute;
    width: 118px;
    height: 5px;
    margin-left: 5px;
    margin-top: 10px;
  }
  .steps-stops {
    height: 25px;
    width: 25px;
    margin: 11px 10px;
    border-radius: 50%;
    background: #fd7702;
  }
  .step-wrap {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: transparent;
    margin-top: 125px;
    margin-left: -2px;
    border: 2px solid #fd7702;
  }
  .end-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: -45px;
    margin-left: 15px;
  }
  .inverted-end-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: absolute;
    margin-top: 280px;
    margin-left: 15px;
  }
}

@media (max-width: 600px) {
  #process .container-fluid {
    width: 90%;
  }
}

@media (max-width: 765px) {
  .verticle-line {
    width: 70px;
  }
}

@media (max-width: 765px) {
  #hide {
    visibility: hidden;
  }

  .steps-two,
  .steps-three,
  .steps-four h3 {
    margin-top: 30px;
  }
}
.back-orange {
  background: #fd7702;
}

.back-blue {
  background: #04165d;
}

.icon3 {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 60px;
  /* cursor: pointer; */
}

.arrow {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: #04165d;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  animation: arrow 700ms linear infinite;
}

.arrow::after,
.arrow::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color: #04165d;
}

.arrow::after {
  top: -12px;
  transform: rotate(45deg);
}

.arrow::before {
  top: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  transform: rotate(-45deg);
}

.icon2 {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 60px;
  /* cursor: pointer; */
}

.arrow2 {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: white;
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, .2); */
  animation: arrow 700ms linear infinite;
}

.arrow2::after,
.arrow2::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color: white;
}

.back-orange {
  background: #fd7702;
}

#h4hover {
  text-decoration: none;
}
#h4hover:hover {
  color: #fd7702;
}

/* 1 */
#bodyAni {
  display: flex;
  justify-content: center;
  align-items: center;
}
#h22 {
  position: relative;
  font-size: 3vw;
  color: white;
  -webkit-text-stroke: 0.2vw #04165d;
  text-transform: uppercase;
}
#h22::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fd7702;
  white-space: nowrap;
  -webkit-text-stroke: 0vw #fd7702;
  border-right: 2px solid #fd7702;
  overflow: hidden;
  animation: animate 5s linear infinite;
}
@keyframes animate {
  0%,
  10%,
  100% {
    width: 0;
  }
  70%,
  90% {
    width: 100%;
  }
}

#bgblue {
  background-color: #04165d;
  height: 460px;
}
#blogi {
  color: white;
  margin-top: 180px;
}
#ourblog1 {
  color: white;
}
#bloghr {
  height: 7px;
  width: 30%;
  color: white;
  margin-left: 190px;
}

.Blog1container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin-block: 2rem;
  gap: 2rem;
  margin-top: 16px;
  margin-left: -170px;
}

#Blog1img {
  max-width: 100%;
  display: block;
  object-fit: cover;
}

.Blog1card {
  display: flex;
  flex-direction: column;
  width: clamp(10rem, calc(14rem + 2vw), 22rem);
  overflow: hidden;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ece9e6;
  background: linear-gradient(to right, #ffffff, #ece9e6);
}

.Blog1card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tag {
  align-self: flex-start;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  font-size: 0.75rem;
}

.tag + .tag {
  margin-left: 0.5em;
}

.tag-blue {
  background: #56ccf2;
  background: linear-gradient(to bottom, #2f80ed, #56ccf2);
  color: #fafafa;
}

.tag-brown {
  background: #d1913c;
  background: linear-gradient(to bottom, #ffd194, #d1913c);
  color: #fafafa;
}

.tag-red {
  background: #cb2d3e;
  background: linear-gradient(to bottom, #ef473a, #cb2d3e);
  color: #fafafa;
}

.Blogcard__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.Bloguser {
  display: flex;
  gap: 0.5rem;
}
#blogshow {
  display: none;
}

#arrowh {
  font-size: 100px;
  margin-left: 300px;
  animation: pulse 1.1s ease infinite;
}

#confoot {
  margin-top: -330px;
}

/* ecommerce responsive */
@media (max-width: 765px) {
  #ecomcard {
    text-align: left;
  }
  .ecomcardh {
    background-color: #0043ac;
    width: 350px;
    line-height: 0.7cm;
    color: white;
    font-size: 1rem;
    background-image: linear-gradient(180deg, #0043ac, #00156a);
  }
  #teamimg {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: white;
  }
  #homevideo {
    display: none;
  }

  #team3 img {
    margin-top: 10px;
    height: 80px;
    width: 80px;
  }
}

@media (max-width: 765p) {
  /* ecommerce website */
  #ecomcard {
    text-align: left;
  }
  .ecomcardh {
    background-color: #0043ac;
    width: 350px;
    line-height: 0.7cm;
    color: lightgray;
    font-size: 1rem;
    font-style: normal;
    background-image: linear-gradient(180deg, #0043ac, #00156a);
  }
  #teamimg {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: white;
  }
  #teamimgE {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: white;
    margin-left: 120px;
    margin-top: 10px;
  }

  #team3 img {
    margin-top: 10px;
    height: 80px;
    width: 80px;
  }
}
#HomeBanner {
  display: none;
}

/* ---------------------------blog card------------------------------- */

.shell {
  padding: 80px 0;
}
.wsk-cp-product {
  background: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
  margin: 20px auto;
}
.wsk-cp-img {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -o-transform: translate(-50%);
  -khtml-transform: translate(-50%);
  width: 100%;
  padding: 15px;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-img img {
  width: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
}
.wsk-cp-product:hover .wsk-cp-img {
  top: -40px;
}
.wsk-cp-product:hover .wsk-cp-img img {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.wsk-cp-text {
  padding-top: 80%;
}
.wsk-cp-text .category {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 45px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.wsk-cp-text .category > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
}
.wsk-cp-text .category > span {
  padding: 12px 30px;
  border: 1px solid #fd7702;
  background: #fd7702;
  color: #fff;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 27px;
  transition: all 0.05s ease-in-out;
}
.wsk-cp-product:hover .wsk-cp-text .category > span {
  border-color: #ddd;
  box-shadow: none;
  padding: 11px 28px;
}
.wsk-cp-product:hover .wsk-cp-text .category {
  margin-top: 0px;
}
.wsk-cp-text .title-product {
  text-align: center;
}
.wsk-cp-text .title-product h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 15px auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
.wsk-cp-text .description-prod p {
  font-size: 16px;
  margin: 0;
}
/* Truncate */
.wsk-cp-text .description-prod {
  text-align: center;
  width: 100%;
  height: 62px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
}

.card-footer .wcf-right {
  float: right;
}

.price {
  font-size: 18px;
  font-weight: bold;
}

a.buy-btn {
  display: block;
  color: #212121;
  text-align: center;
  font-size: 18px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #212121;
  transition: all 0.2s ease-in-out;
}
a.buy-btn:hover,
a.buy-btn:active,
a.buy-btn:focus {
  border-color: #ff9800;
  background: #ff9800;
  color: #fff;
  text-decoration: none;
}
.wsk-btn {
  display: inline-block;
  color: #212121;
  text-align: center;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  border-color: #ff9800;
  background: #ff9800;
  padding: 12px 30px;
  border-radius: 27px;
  margin: 0 5px;
}
.wsk-btn:hover,
.wsk-btn:focus,
.wsk-btn:active {
  text-decoration: none;
  color: #fff;
}
.red {
  color: #f44336;
  font-size: 22px;
  display: inline-block;
  margin: 0 5px;
}
@media screen and (max-width: 991px) {
  .wsk-cp-product {
    margin: 40px auto;
    height: 80px;
  }
  .wsk-cp-product .wsk-cp-img {
    top: -40px;
  }
  .wsk-cp-product .wsk-cp-img img {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  .wsk-cp-product .wsk-cp-text .category > span {
    border-color: #ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product .wsk-cp-text .category {
    margin-top: 0px;
  }
  a.buy-btn {
    border-color: #ff9800;
    background: #ff9800;
    color: #fff;
  }
}
#blogcard {
  margin-top: -480px;
  margin-left: 630px;
}
#rightA {
  font-size: 70px;
  color: #00156a;
  margin-left: 200px;
  margin-top: 140px;
}
@media (max-width: 765px) {
  .shell {
    padding: 80px 0;
    margin-left: -35px;
  }
  .wsk-cp-product {
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    margin: 20px auto;
  }
  .wsk-cp-img {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -khtml-transform: translate(-50%);
    width: 100%;
    padding: 15px;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-img img {
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-radius: 6px;
  }
  .wsk-cp-product:hover .wsk-cp-img {
    top: -40px;
  }
  .wsk-cp-product:hover .wsk-cp-img img {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  .wsk-cp-text {
    padding-top: 100%;
  }
  .wsk-cp-text .category {
    text-align: center;
    font-size: 12px;

    font-weight: bold;
    padding: 5px;
    margin-bottom: 45px;
    position: relative;
    transition: all 0.2s ease-in-out;
  }
  .wsk-cp-text .category > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
  }
  .wsk-cp-text .category > span {
    /* padding: 12px 30px; */

    border: 1px solid #fd7702;
    background: #fd7702;
    color: #fff;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    border-radius: 27px;
    transition: all 0.05s ease-in-out;
  }
  .wsk-cp-product:hover .wsk-cp-text .category > span {
    border-color: #ddd;
    box-shadow: none;
    padding: 11px 28px;
  }
  .wsk-cp-product:hover .wsk-cp-text .category {
    margin-top: 0px;
  }
  .wsk-cp-text .title-product {
    text-align: center;
  }
  .wsk-cp-text .title-product h3 {
    font-size: 10px;
    font-weight: bold;
    margin: 15px auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: -50px;
  }
  .wsk-cp-text .description-prod p {
    font-size: 8px;
    margin: 0;
  }
  /* Truncate */
  .wsk-cp-text .description-prod {
    text-align: center;
    width: 100%;
    height: 62px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 15px;
  }

  .card-footer .wcf-right {
    float: right;
  }

  .price {
    font-size: 18px;
    font-weight: bold;
  }

  a.buy-btn {
    display: block;
    color: #212121;
    text-align: center;
    font-size: 18px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    border: 1px solid #212121;
    transition: all 0.2s ease-in-out;
  }
  a.buy-btn:hover,
  a.buy-btn:active,
  a.buy-btn:focus {
    border-color: #ff9800;
    background: #ff9800;
    color: #fff;
    text-decoration: none;
  }
  .wsk-btn {
    display: inline-block;
    color: #212121;
    text-align: center;
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    border-color: #ff9800;
    background: #ff9800;
    padding: 12px 30px;
    border-radius: 27px;
    margin: 0 5px;
  }
  .wsk-btn:hover,
  .wsk-btn:focus,
  .wsk-btn:active {
    text-decoration: none;
    color: #fff;
  }
  .red {
    color: #f44336;
    font-size: 22px;
    display: inline-block;
    margin: 0 5px;
  }
  @media screen and (max-width: 991px) {
    .wsk-cp-product {
      margin: 40px auto;
    }
    .wsk-cp-product .wsk-cp-img {
      top: -40px;
    }
    .wsk-cp-product .wsk-cp-img img {
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3),
        0 15px 12px rgba(0, 0, 0, 0.22);
    }
    .wsk-cp-product .wsk-cp-text .category > span {
      border-color: #ddd;
      box-shadow: none;
      padding: 11px 28px;
    }
    .wsk-cp-product .wsk-cp-text .category {
      margin-top: 0px;
    }
    a.buy-btn {
      border-color: #ff9800;
      background: #ff9800;
      color: #fff;
    }
  }
  #blogcard {
    display: none;
  }
  #rightA {
    font-size: 70px;
    color: #00156a;
    margin-left: 40px;
    margin-top: 100px;
  }
  #colb {
    padding: 1px;
    margin: 1px;
  }

  #blogcardHide {
    display: none;
  }
}
#blogshow {
  display: none;
  visibility: hidden;
}
#blogrow {
  height: 400px;
  width: 100%;
}
#blogcol {
  background-color: #04165d;
}
#blogcardShow {
  display: none;
}

/* newblog */
#blogheading {
  margin-top: 250px;
  font-weight: normal;
}
.row-outer {
  padding: 20px 0;
  height: 400px;
}
.heading {
  text-align: center;
}
.container8 {
  width: 95%;

  max-width: 1160px;
  margin: auto;
  margin-left: 300px;
  margin-top: -260px;
}
.heading {
  text-align: center;
  padding: 20px 0;
}

.grid-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "item1 item1 item2 item3"
    "item4 item4 item2 item5"
    "item4 item4 item2 item6";
  grid-gap: 15px;
}

.ite {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  min-height: 10rem;
  cursor: pointer;
}

.item1 {
  grid-area: item1;

  background: #4b00ff url(./images/b1.jpg);
  background-position: top !important;
}
.item2 {
  grid-area: item2;
  background: #4b00ff url(./images/b4.webp);
}
.item3 {
  grid-area: item3;
  background: #4b00ff url(./images/b3.webp);
}
.item4 {
  grid-area: item4;
  background: #4b00ff url(./images/b2.webp);
}
.item6 {
  grid-area: item6;
  background: #4b00ff url(./images/b5.jpg);
}
.item5 {
  grid-area: item5;
  background: #4b00ff url(./images/b5.png);
}
@media (max-width: 768px) {
  #connecttop{
    margin-top: 50px;
  }
  #gsss{
    display: none;
  }
  #elfsight{
    margin-top: 100px;
  display: none;
  }
  .grid-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "item1 item1 item2"
      "item3 item4 item2"
      "item5 item6 item2";
  }
}
@media (max-width: 620px) {
  .grid-gallery {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "item1 item3"
      "item2 item4"
      "item2 item5"
      "item2 item6";
  }
}
/* @media (max-width: 360px) {
  .grid-gallery {
    grid-template-columns: 1fr;
    grid-template-areas:
      "item1"
      "item2"
      "item3"
      "item4"
      "item5"
      "item6";
  }
  .item2 {
    background-position: top;
  }
} */
#y1 {
  background-color: #00aff0;
}

#pdf1 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  /* margin-left: -50px; */
}

#re1 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 4px;
  padding: 4px;
}

#dropdowntabout {
  border: white;
  outline-color: white;
  background-color: white;
  color: #04165d;
  font-weight: 700;
  font-size: 22.3px;
  margin: 10px;
  padding: 10px;
  text-align: left;
}
#blog1img {
  width: 100%;
  height: 350px;
}
#blog1img22 {
  width: 100%;
  height: 100%;

}

#blog1img1 {
  width: 80%;
  height: 580px;
}
#blog1img2 {
  width: 100%;
  height: 450px;
}
.modalemailabel{
  margin-left: 30px;
}
/* -------------------------------------------------------------------------- */

#thnkh5{
  margin-left: 280px;
  margin-right: 280px;
  color: #333;
  font-weight: normal;
  line-height: 30px;
  margin-top: 10px;
}
#thnqh{
  color: black;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  margin-left: -650px;
  line-height: 3.5px;
}
#thqdiv{

  margin-top: 100px;
}
#thqp{
  line-height: 2.5;
}
#thqimg {
  width: 100%;
  height: 460px;
}


.ite {
  /* opacity: 0; */
  transition: .5s ease;
  background-color: rgba(0,0,0,.5);

}
.ite :hover{

    opacity: 0.5;
    background: #00aff0;

    }

    .whoWeAreBody{
      text-align: left;
      margin-bottom: 0.5rem;
    }
    .whoWeAreContent{
      font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #4C4C4C;


    }

