#hidelogo1{
    height: 40px;
}
.s1{
    height: 56px;
    background-color:transparent ;
    border: none;
    margin-top: 15px;
}
#hidelogo1{
    height: 55px;
    margin-top: 10px;
    margin-left: "-20px" 
}
@media(max-width:390){

}#hidelogo1{
    margin-left: "-4px" 
}

#g1{
    text-decoration: none;
    color: white;
    margin-top: 25px;
}

#homecall{
    background-color: #04165D;
    width: 100%;
}
#homeh2{
    font-weight: bold;
    margin-top: -11px;
}
#homeh4{
    text-align: center;
    font-weight: normal;
}
.s2{
    text-decoration: none;
    color: white;
}
.s3{
    margin-top: -40px;
}
#g2{
    margin-top: -70px;
}
#g3{
    color: black;
    font-weight: bold;
}
#g4{
    text-align: center;
    color: darkgray;
}
#g5{
    width: 30%;
    border: 1px solid #04165D;
    margin: 10px;
    margin-top: 45px;
    
}
.s4{
    color: black;
    font-weight: bold;
    margin-top: 27px;
}
#g6{
    margin-left: -22px;
    margin-top: 20px;
}
@media(max-width:390px){
  #g2{
      margin-top: -174px;
  }
}
@media(max-width:430px){
    #g2{
        margin-top: -175px;
    }
  }
#knowmore4{
    width: 28%;
    height: 41px;
    animation: pulse 1.1s ease infinite;
    align-items: center;
    background-color: #04165D;
}

#knowmore{
    width: 142px;
    height: 48px;
    top: 419px;
    left: 594px;
    border-radius: 4px;
    margin-left: -80%;
    animation: pulse 1.1s ease infinite;
    margin-top: 2.8%;
}
/* Media query for max-width: 430px */
@media (max-width: 430px) {
    #knowmore {
      margin-left: -63%;
    }
  }
  
  /* Media query for max-width: 390px */
  @media (max-width: 390px) {
    #knowmore {
      margin-left: -56%;
    }
  }
  
#whoweare{
    /* color:#2D2D2D; */
    margin-bottom: 1.2rem;
    line-height: 2.2rem;
    
    
}
#TrustUsSection{
   
    line-height: 0.1rem;
    
}
@media(max-width:390px){
    .TrustUsSection{
        margin-top: -20%;
    }
}
@media(max-width:430px){
    .TrustUsSection{
        margin-top: -20%;
    }
}
.TrustUsSectiontextColor{
   color: #2D2D2D;
}
.TickOptions{
    line-height: 1.5rem;
    color: #4c4c4c;
}
#whoweareServices{
    margin-bottom: 1.2rem;
    line-height: 1.2rem;
    color: #FD7702;
}
@media(max-width:390px){
    #whoweareServices{
        text-align:left;
    }
  
}
@media(max-width:430px){
    #whoweareServices{
        text-align:left;
    }
  
}
#whowearecenter{
    color:#2D2D2D;
    margin-bottom: 1.2rem;
    line-height: 2.2rem;
    text-align: center;
}
#whoweareServices{
    margin-bottom: 1.2rem;
    line-height: 2.2rem;
    color: #FD7702;
}
@media(max-width:500px){
    .slick-slider.slick-initialized {
        padding: 0;
        margin: 0;
        height: 150px !important;
    }
}
